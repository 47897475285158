import classNames from 'classnames';

import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import Heading from '@tb-core/components/simple/heading';
import ItemList from '@tb-core/components/simple/item-list';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import {
    childItemAdapter,
    ctaGroupAdapter
} from '@tb-core/components/styled/modules/helpers';
import StandardBucketItem from '@tb-core/components/styled/modules/standard-bucket/standard-bucket-item';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import usePageContext from '@tb-core/hooks/use-page-context';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

export interface StandardContentProps extends WebpageContentProps {
    className?: string;
}

const StandardBucketContent = ({
    className,
    colorTheme,
    description,
    entriesCollection: { items },
    entryTitle,
    footnote,
    title,
    type
}: StandardContentProps) => {
    /* Extract Link and WebpageContent types from entriesCollection items */
    const { Link, WebpageContent } = entriesByTypename(items);
    const {
        topicMeta: { dayparts: daypartsConfig },
        seoTitle
    } = usePageContext().content.pageData;

    const bucketItems = childItemAdapter({
        colorTheme,
        dataLayerTemplate: {
            'Analytics-Action': type,
            entry_title: entryTitle,
            page_title: seoTitle
        },
        daypartsConfig,
        moduleContent: WebpageContent
    });

    const ctaGroup = ctaGroupAdapter(
        colorTheme,
        {
            'Analytics-Action': type,
            'Analytics-Value': title,
            entry_title: entryTitle,
            page_title: seoTitle
        },
        Link
    );
    const ctaLimit = 2;
    const isGraphicCta =
        typeof (ctaGroup && ctaGroup[0] && ctaGroup[0].graphic) !== 'undefined';

    const titleTheme = {
        color: colorTheme?.titleText
    };
    const footerTheme = {
        color: colorTheme?.legalText
    };

    const displayTitle = title && interpolate(title, tokenStrings);

    return (
        <div className={className}>
            {displayTitle && (
                <Heading
                    dangerouslySetInnerHTML={{ __html: displayTitle }}
                    style={titleTheme}
                    tag="h2"
                />
            )}

            {description?.json && (
                <div>
                    <ContentfulRichTextWithTheme
                        colorTheme={colorTheme}
                        node={description.json}
                        renderOptions={{ renderText }}
                    />
                </div>
            )}

            {bucketItems && (
                <FlexGridLayout
                    align="stretch"
                    className={styles['standard-buckets']}
                    justify="center"
                    wrap={true}
                >
                    <ItemList
                        item={StandardBucketItem}
                        itemProps={bucketItems}
                    />
                </FlexGridLayout>
            )}

            {/* CTA Group */}
            {ctaGroup && (
                <FlexGridLayout
                    align="stretch"
                    className={classNames(styles['standard-bucket-nav'], {
                        [styles['nav-graphic']]: isGraphicCta
                    })}
                    justify="center"
                >
                    <BrandedCtaGroup ctaGroup={ctaGroup.slice(0, ctaLimit)} />
                </FlexGridLayout>
            )}

            {footnote?.json && (
                <footer style={footerTheme}>
                    <ContentfulRichTextWithTheme
                        node={footnote.json}
                        renderOptions={{ renderText }}
                    />
                </footer>
            )}
        </div>
    );
};
export default StandardBucketContent;
